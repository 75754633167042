import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StreetManagementService } from './street-management.service';

@Injectable({
  providedIn: 'root',
})
export class CountryConfigResolveService {
  constructor(private streetManagementService: StreetManagementService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.streetManagementService.getCountriesWithStructuredAddresses().subscribe();
  }
}
