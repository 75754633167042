import { AddressComponentModel, StreetFormModel } from '@nexuzhealth/shared/street-management/domain';
import { mapAddressComponentNameToTranslationForm } from './address-component-name-to-translation-form.mapper';
import { mapAddressComponentToSubMunicipality } from './address-component-to-sub-municipality.mapper';
import { mapAddressComponentCodeToForm } from './address-component-code-to-form.mapper';

export function mapAddressComponentToStreetForm(addressComponent: AddressComponentModel): StreetFormModel {
  return {
    name: addressComponent.name,
    subMunicipality: addressComponent.parent
      ? mapAddressComponentToSubMunicipality(addressComponent.parent)
      : undefined,
    countryId: addressComponent.parent?.parent?.parent?.name,
    translations: addressComponent.names?.map((name) => mapAddressComponentNameToTranslationForm(name)),
    codes: addressComponent.codes?.map((name) => mapAddressComponentCodeToForm(name)),
    status: addressComponent.status,
    sendVersion: addressComponent.sendVersion,
  };
}
