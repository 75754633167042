import { Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngneat/effects';
import { Actions } from '@ngneat/effects-ng';
import { withTransaction } from '@datorama/akita';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  UserContextInformation,
  UserContextInformationPractitioner,
  UserContextInformationUser,
  UserContextInformationUserUpdate,
  UserContextInvoiceInformation,
  UserInvoiceInformation,
} from '@nexuzhealth/shared/user-management/domain';
import { of, throwError } from 'rxjs';
import { Error } from '@nexuzhealth/shared/domain';
import { I18NextPipe } from 'angular-i18next';
import { ToastrHelperService } from '@nexuzhealth/shared/util';
import { PersonInformation } from '@nexuzhealth/shared/person/domain';
import { UserManagementHttpService } from '../api/user-management-http.service';
import {
  fetchUserContextInformation,
  fetchUserContextInformationError,
  fetchUserContextInformationSuccess,
  fetchUserContextInvoiceInformation,
  fetchUserContextInvoiceInformationError,
  fetchUserContextInvoiceInformationSuccess,
  fetchUserInvoiceInformation,
  fetchUserInvoiceInformationError,
  fetchUserInvoiceInformationSuccess,
  updateUserContextInformationGeneralInfo,
  updateUserContextInformationGeneralInfoError,
  updateUserContextInformationGeneralInfoSuccess,
  updateUserContextInformationPractitioner,
  updateUserContextInformationPractitionerError,
  updateUserContextInformationPractitionerSuccess,
  updateUserContextInformationUser,
  updateUserContextInformationUserError,
  updateUserContextInformationUserSuccess,
  updateUserContextInvoiceInformation,
  updateUserContextInvoiceInformationError,
  updateUserContextInvoiceInformationSuccess,
  updateUserInvoiceInformation,
  updateUserInvoiceInformationError,
  updateUserInvoiceInformationSuccess,
} from './user-management.actions';
import { UserManagementState, UserManagementStore } from './user-management.store';

@Injectable({ providedIn: 'root' })
export class UserManagementEffects {
  constructor(
    private userManagementHttpService: UserManagementHttpService,
    private userManagementStore: UserManagementStore,
    private toastrHelperService: ToastrHelperService,
    private i18n: I18NextPipe
  ) {}

  /*
              =====================================
              ==== User Context Information ====
              =====================================
              */
  fetchUserContextInformation$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(fetchUserContextInformation),
        withTransaction(() => {
          this.userManagementStore.reset();
          this.userManagementStore.setError(null);
          this.userManagementStore.setLoading(true);
        }),
        switchMap((payload: Record<string, string>) =>
          this.userManagementHttpService.getUserContextInformation(payload['resourceName']).pipe(
            map((userContextInformation: UserContextInformation) =>
              fetchUserContextInformationSuccess({ userContextInformation })
            ),
            catchError((error: Error) => of(fetchUserContextInformationError({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  fetchUserContextInformationSuccess$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(fetchUserContextInformationSuccess),
        withTransaction((payload) => {
          const userContextInformation: UserContextInformation = payload['userContextInformation'];
          this.userManagementStore.update({
            generalInfo: userContextInformation.generalInfo,
            user: userContextInformation.user,
            practitioner: userContextInformation.practitioner,
            userInvoiceInformation: userContextInformation.userInvoiceInformation,
            userContextInvoiceInformation: userContextInformation.userContextInvoiceInformation,
          });
          this.userManagementStore.setLoading(false);
        })
      ),
    { dispatch: false }
  );

  fetchUserContextInformationError$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(fetchUserContextInformationError),
        withTransaction((payload) => {
          this.userManagementStore.update((state) => ({ ...state, pageError: payload['error'] }));
          this.userManagementStore.setLoading(false);
        })
      ),
    { dispatch: false }
  );

  /*
              =======================================================
              ==== User Context Information General info (Person data) ====
              =======================================================
              */
  updateUserContextInformationGeneralInfo$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInformationGeneralInfo),
        switchMap((payload) =>
          this.userManagementHttpService
            .updateUserContextInformationGeneralInfo(
              payload['userContextName'],
              payload['userContextInformationGeneralInfo'] as PersonInformation
            )
            .pipe(
              map((userContextInformationGeneralInfo: PersonInformation) =>
                updateUserContextInformationGeneralInfoSuccess({
                  userContextInformationGeneralInfo: {
                    ...userContextInformationGeneralInfo,
                    inss: (payload['userContextInformationGeneralInfo'] as PersonInformation).inss,
                  },
                })
              ),
              catchError((error: Error) => of(updateUserContextInformationGeneralInfoError({ error })))
            )
        )
      ),
    { dispatch: true }
  );

  updateUserContextInformationGeneralInfoSuccess$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInformationGeneralInfoSuccess),
        withTransaction((payload) => {
          const userContextInformationGeneralInfo: PersonInformation = payload['userContextInformationGeneralInfo'];
          this.userManagementStore.update((state) => ({
            ...state,
            generalInfo: { ...userContextInformationGeneralInfo },
            successfulEdit: true,
          }));
          this.toastrHelperService.success('_user-management._personal-data.update-success');
        })
      ),
    { dispatch: false }
  );

  updateUserContextInformationGeneralInfoError$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInformationGeneralInfoError),
        withTransaction((payload) => {
          const error = payload['error'];
          this.userManagementStore.setError({ ...error });
          this.userManagementStore.update((state) => ({ ...state, successfulEdit: false }));

          switch (error.kind) {
            case 'common_person_identifier-gender-mismatch':
              this.toastrHelperService.error(
                '_personal._identifier-gender-mismatch-toast.message',
                '_user-management._personal-data.update-error'
              );
              break;
            default:
              this.toastrHelperService.error('_user-management._personal-data.update-error');
          }
        })
      ),
    { dispatch: false }
  );

  /*
              =======================================================
              ==== User Context Information User (User data) ====
              =======================================================
              */
  updateUserContextInformationUser$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInformationUser),
        switchMap((payload) =>
          this.userManagementHttpService
            .updateUserContextInformationUser(
              payload['userContextName'],
              payload['userContextInformationUser'] as UserContextInformationUserUpdate
            )
            .pipe(
              map((userContextInformationUser: UserContextInformationUser) =>
                updateUserContextInformationUserSuccess({
                  userContextInformationUser: {
                    ...userContextInformationUser,
                    userEmail: payload['userContextInformationUser'].userEmail,
                  },
                })
              ),
              catchError((error: Error) => of(updateUserContextInformationUserError({ error })))
            )
        )
      ),
    { dispatch: true }
  );

  updateUserContextInformationUserSuccess$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInformationUserSuccess),
        withTransaction((payload) => {
          const userContextInformationUser: UserContextInformationUser = payload['userContextInformationUser'];
          this.userManagementStore.update((state) => ({
            ...state,
            user: { ...state.user, ...userContextInformationUser },
            successfulEdit: true,
          }));
          this.toastrHelperService.success('_user-management._user-data.update-success');
        })
      ),
    { dispatch: false }
  );

  updateUserContextInformationUserError$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInformationUserError),
        withTransaction((payload) => {
          this.userManagementStore.setError({ ...payload['error'] });
          this.userManagementStore.update((state) => ({ ...state, successfulEdit: false }));
          if (payload['error'].kind === 'iam_auth_email-already-in-use') {
            this.toastrHelperService.error(
              '_user-management._user-email.update-error%emailAddress',
              null,
              {},
              {
                message: { emailAddress: payload['error']['extraData']['email'] },
              }
            );
          } else {
            this.toastrHelperService.error('_user-management._user-data.update-error');
          }
        })
      ),
    { dispatch: false }
  );

  /*
               ==========================================================================
               ==== User Context Practitioner Information (Professional Information) ====
               ==========================================================================
               */
  updateUserContextInformationPractitioner$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInformationPractitioner),
        switchMap((payload) => {
          const userContextName = payload['userContextName'];
          if (!userContextName) {
            return throwError('UserContextName cannot be null');
          }

          return this.userManagementHttpService
            .updateUserProfessionalInformation(
              userContextName,
              payload['isNihiiPending'],
              payload['userProfessionalInformation']
            )
            .pipe(
              map((userContextInformationPractitioner: UserContextInformationPractitioner) =>
                updateUserContextInformationPractitionerSuccess({
                  userContextInformationPractitioner,
                  isNihiiPending: payload['isNihiiPending'],
                })
              ),
              catchError((error: Error) => of(updateUserContextInformationPractitionerError({ error })))
            );
        })
      ),
    { dispatch: true }
  );

  updateUserContextInformationPractitionerSuccess$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInformationPractitionerSuccess),
        withTransaction((payload) => {
          const userContextInformationPractitioner: UserContextInformationPractitioner =
            payload['userContextInformationPractitioner'];
          const isNihiiPending: boolean = payload['isNihiiPending'];

          this.userManagementStore.update((state: UserManagementState) => ({
            ...state,
            user: state.user ? { ...state.user, isNihiiPending } : null,
            practitioner: { ...state.practitioner, ...userContextInformationPractitioner },
            successfulEdit: true,
          }));
          this.toastrHelperService.success('_user-account._professional.update-success');
        })
      ),
    { dispatch: false }
  );

  updateUserContextInformationPractitionerError$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInformationPractitionerError),
        withTransaction((payload) => {
          this.userManagementStore.setError({ ...payload['error'] });
          this.userManagementStore.update((state) => ({ ...state, successfulEdit: false }));
          this.toastrHelperService.error('_user-account._professional.update-error');
        })
      ),
    { dispatch: false }
  );

  /*
                 =====================================
                 ==== User Invoice Information ====
                 =====================================
                 */
  fetchUserInvoiceInformation$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(fetchUserInvoiceInformation),
        switchMap((payload: Record<string, string>) =>
          this.userManagementHttpService.getUserInvoiceInformation(payload['userName']).pipe(
            map((userInvoiceInformation: UserInvoiceInformation) =>
              fetchUserInvoiceInformationSuccess({ userInvoiceInformation })
            ),
            catchError((error: Error) => of(fetchUserInvoiceInformationError({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  fetchUserInvoiceInformationSuccess$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(fetchUserInvoiceInformationSuccess),
        withTransaction((payload) => {
          const userInvoiceInformation: UserInvoiceInformation = payload['userInvoiceInformation'];
          this.userManagementStore.update((state) => ({
            ...state,
            userInvoiceInformation,
          }));
          this.userManagementStore.setLoading(false);
        })
      ),
    { dispatch: false }
  );

  fetchUserInvoiceInformationError$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(fetchUserInvoiceInformationError),
        withTransaction((payload) => {
          this.userManagementStore.setError(payload['error']);
          this.userManagementStore.setLoading(false);
        })
      ),
    { dispatch: false }
  );

  updateUserInvoiceInformation$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserInvoiceInformation),
        switchMap((payload) => {
          const userName = payload['userName'];
          if (!userName) {
            return throwError('UserName cannot be null');
          }

          return this.userManagementHttpService
            .updateUserInvoiceInformation(userName, <UserInvoiceInformation>payload['userInvoiceInformation'])
            .pipe(
              map((userInvoiceInformation: UserInvoiceInformation) =>
                updateUserInvoiceInformationSuccess({ userInvoiceInformation })
              ),
              catchError((error: Error) => of(updateUserInvoiceInformationError({ error })))
            );
        })
      ),
    { dispatch: true }
  );

  updateUserInvoiceInformationSuccess$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserInvoiceInformationSuccess),
        withTransaction((payload) => {
          const userInvoiceInformation: UserInvoiceInformation = payload['userInvoiceInformation'];
          this.userManagementStore.update((state) => ({
            ...state,
            userInvoiceInformation,
            successfulEdit: true,
          }));
          this.toastrHelperService.success('_user-account._finance.update-success');
        })
      ),
    { dispatch: false }
  );

  updateUserInvoiceInformationError$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserInvoiceInformationError),
        withTransaction((payload) => {
          this.userManagementStore.setError({ ...payload['error'] });
          this.userManagementStore.update((state) => ({ ...state, successfulEdit: false }));
          this.toastrHelperService.error('_user-account._finance.update-error');
        })
      ),
    { dispatch: false }
  );

  /*
              =====================================
              ==== User Context Invoice Information ====
              =====================================
              */
  fetchUserContextInvoiceInformation$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(fetchUserContextInvoiceInformation),
        switchMap((payload) =>
          this.userManagementHttpService.getUserContextInvoiceInformation(payload['userContextName']).pipe(
            map((userContextInvoiceInformation: UserContextInvoiceInformation) =>
              fetchUserContextInvoiceInformationSuccess({ userContextInvoiceInformation })
            ),
            catchError((error: Error) => of(fetchUserContextInvoiceInformationError({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  fetchUserContextInvoiceInformationSuccess$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(fetchUserContextInvoiceInformationSuccess),
        withTransaction((payload) => {
          const userContextInvoiceInformation: UserContextInvoiceInformation = payload['userContextInvoiceInformation'];
          this.userManagementStore.update((state) => ({
            ...state,
            userContextInvoiceInformation,
          }));

          this.userManagementStore.setLoading(false);
        })
      ),
    { dispatch: false }
  );

  fetchUserContextInvoiceInformationError$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(fetchUserContextInvoiceInformationError),
        withTransaction((payload) => {
          this.userManagementStore.setError(payload['error']);
          this.userManagementStore.setLoading(false);
        })
      ),
    { dispatch: false }
  );

  updateUserContextInvoiceInformation$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInvoiceInformation),
        switchMap((payload) => {
          const userContextName = payload['userContextName'];
          if (!userContextName) {
            return throwError('UserContexName cannot be null');
          }
          return this.userManagementHttpService
            .updateUserContextInvoiceInformation(userContextName, payload['userContextInvoiceInformation'])
            .pipe(
              map((userContextInvoiceInformation: UserContextInvoiceInformation) =>
                updateUserContextInvoiceInformationSuccess({ userContextInvoiceInformation })
              ),
              catchError((error: Error) => of(updateUserContextInvoiceInformationError({ error })))
            );
        })
      ),
    { dispatch: true }
  );

  updateUserContextInvoiceInformationSuccess$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInvoiceInformationSuccess),
        withTransaction((payload) => {
          const userContextInvoiceInformation: UserContextInvoiceInformation = payload['userContextInvoiceInformation'];
          this.userManagementStore.update((state) => ({
            ...state,
            userContextInvoiceInformation,
            successfulEdit: true,
          }));
          this.toastrHelperService.success('_user-management._user-context-invoice.update-success');
        })
      ),
    { dispatch: false }
  );

  updateUserContextInvoiceInformationError$ = createEffect(
    (actions: Actions) =>
      actions.pipe(
        ofType(updateUserContextInvoiceInformationError),
        withTransaction((payload) => {
          this.userManagementStore.setError(payload['error']);
          this.userManagementStore.update((state) => ({ ...state, successfulEdit: false }));
          this.toastrHelperService.error('_user-management._user-context-invoice.update-error');
        })
      ),
    { dispatch: false }
  );
}
