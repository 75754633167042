import {
  AddressComponentListModel,
  AddressComponentModel,
  StreetModel,
} from '@nexuzhealth/shared/street-management/domain';
import { mapAddressComponentToSubMunicipality } from './address-component-to-sub-municipality.mapper';

export function mapAddressComponentListToStreets(list: AddressComponentListModel): StreetModel[] {
  return list.addressComponents.map((addressComponent) => mapAddressComponentToStreet(addressComponent));
}

export function mapAddressComponentToStreet(addressComponent: AddressComponentModel): StreetModel {
  return {
    name: addressComponent.name ?? '',
    translation: addressComponent.names ? addressComponent.names[0]?.value ?? '' : '',
    subMunicipality: addressComponent.parent
      ? mapAddressComponentToSubMunicipality(addressComponent.parent)
      : undefined,
    postalCode: addressComponent.parent ? addressComponent.parent.postalDescriptor ?? '' : '',
  };
}
