import { AddressComponentModel, AddressComponentType } from '@nexuzhealth/shared/street-management/domain';

export function getFormattedTranslation(addressComponent: AddressComponentModel): string {
  const translation = getFirstTranslation(addressComponent);
  if (addressComponent.type === AddressComponentType.municipality) {
    return translation;
  } else {
    const parentTranslation = getFirstTranslation(addressComponent.parent);
    return `${addressComponent.postalDescriptor ? addressComponent.postalDescriptor + ' ' : ''}${translation}${
      parentTranslation ? ' (' + parentTranslation + ')' : ''
    }`;
  }
}

export function getFirstTranslation(addressComponent: AddressComponentModel | undefined): string {
  return addressComponent?.names ? addressComponent.names[0]?.value ?? '' : '';
}
