import { LogLevel, LogMessage } from '../../log.model';
import { CapturedPayload } from './captured-payload.model';
import { Transporter, TransportTemplate } from './transporter.model';

/**
 * Base Transporter implementation
 */
export abstract class AbstractTransporter<T = LogMessage> implements Transporter<T> {
  template: TransportTemplate<T>;

  protected constructor(template: TransportTemplate<T>) {
    this.template = template;
  }

  write(payload: CapturedPayload, level?: LogLevel) {
    const templatedPayload = this.template(payload);
    this.doWrite(templatedPayload, level);
  }

  abstract doWrite(payload: T, level?: LogLevel): void;
}
