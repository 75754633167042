import { AddressComponentModel, SubMunicipalityFormModel } from '@nexuzhealth/shared/street-management/domain';
import { mapAddressComponentNameToTranslationForm } from './address-component-name-to-translation-form.mapper';
import { mapAddressComponentCodeToForm } from './address-component-code-to-form.mapper';

export function mapAddressComponentToSubMunicipalityForm(
  addressComponent: AddressComponentModel
): SubMunicipalityFormModel {
  const firstTranslation = addressComponent.parent?.names ? addressComponent.parent?.names[0].value : '';
  return {
    name: addressComponent.name,
    municipality: {
      name: addressComponent.parent?.name || '',
      translation: firstTranslation,
    },
    countryId: addressComponent.parent?.parent?.name,
    sendVersion: addressComponent.sendVersion,
    postalCode: addressComponent.postalDescriptor,
    status: addressComponent.status,
    translations: addressComponent.names?.map((name) => mapAddressComponentNameToTranslationForm(name)),
    codes: addressComponent.codes?.map((name) => mapAddressComponentCodeToForm(name)),
  };
}
