import { HttpErrorResponse } from '@angular/common/http';
import { Error as NxhError } from '@nexuzhealth/shared/domain';

/**
 * Allowed Log input types to be received by a MyLogger
 */
export type ErrorSource = string | Error | HttpErrorResponse | NxhError;

export enum LogLevel {
  Info = 4,
  Debug = 3,
  Warn = 2,
  Error = 1,
}

export interface LogMessage {
  traceId: string;
  stack: ErrorSource;
  level: LogLevel;
  timestamp: Date;
  message: string;
  kind?: string;
}
