import { Inject, Injectable } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { HttpErrorResponse } from '@angular/common/http';
import { LogLevel, LogMessage } from './log.model';
import { CapturedPayload, ConsoleTransporter, Transporter, TransporterToken } from './transporters';
import { LoggerConfiguration, NxhLogger } from './logger.model';
import { LoggerConfigurationToken } from './logger.tokens';

/**
 * Default Logger implementation
 */
@Injectable({ providedIn: 'root' })
export class LoggerService<T extends LogMessage> implements NxhLogger {
  private readonly transporters: Transporter<T>[];

  constructor(
    @Inject(LoggerConfigurationToken) private loggerConfig: LoggerConfiguration,
    @Inject(TransporterToken) transporters: Transporter<T>[],
    private i18next: I18NextPipe
  ) {
    /*
     * Ensure array is received, if no received it means only one
     * transporter has been defined and was not configured as multi
     */
    this.transporters = (Array.isArray(transporters) ? transporters : [transporters]).filter(
      (transporter: Transporter<T>) => transporter
    );

    if (!transporters || transporters.length === 0) {
      // in case no transporters were defined we provide the console transporter by default
      this.transporters = [new ConsoleTransporter()];
    }
  }

  /**
   * Logs a info message
   * @param message
   */
  info(message: T): void {
    this.logWith(LogLevel.Info, message);
  }

  /**
   * Logs debug action messages
   * @param message
   */
  debug(message: T): void {
    this.logWith(LogLevel.Debug, message);
  }

  /**
   * Logs warning level messages
   * @param message
   */
  warn(message: T): void {
    this.logWith(LogLevel.Warn, message);
  }

  /**
   * Logs error messages
   * @param error
   */
  error(error: T): void {
    this.logWith(LogLevel.Error, error);
  }

  /**
   * If payload evaluates as should be logged then a macro-task is scheduled
   * @param logLevel
   * @param logMessage
   */
  private logWith(logLevel: LogLevel, logMessage: T) {
    if (this.isEventLevelLessThanLogLevel(logLevel)) {
      let message = null;
      let stack = null;
      if (typeof logMessage === 'string') {
        message = logMessage;
        stack = logMessage;
      } else if (logMessage instanceof HttpErrorResponse) {
        stack = logMessage.error;
      }

      // capture payload, timestamp and level
      const capturedPayload: CapturedPayload = {
        traceId: logMessage.traceId || crypto.randomUUID(),
        stack: stack ?? logMessage.stack,
        level: logLevel,
        timestamp: new Date(),
        message: message ?? logMessage.message ?? this.i18next.transform('something_wrong'),
        kind: logMessage.kind,
      };

      this.transporters.forEach((transport) => {
        // send captured payload to each transporter to be written
        this.execute(transport, capturedPayload, logLevel);
      });
    }
  }

  /**
   * A log-able payload is it which it's log level is less or equal to configured log level
   * @param logLevel
   */
  private isEventLevelLessThanLogLevel(logLevel: LogLevel): boolean {
    // return payload != null && this.loggerConfig.level >= logLevel;
    return this.loggerConfig.level >= logLevel;
  }

  /**
   * Queues the logging event as a micro-task
   * @param transport
   * @param capturedPayload
   * @param level
   */
  private execute(transport: Transporter<T>, capturedPayload: CapturedPayload, level: LogLevel) {
    // send to event queue to minimize the stack processing for the application
    queueMicrotask(() => transport.write(capturedPayload, level));
  }
}
