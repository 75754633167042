import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ErrorSource } from './log.model';
import { NxhLogger } from './logger.model';
import { NxhLoggerToken } from './logger.tokens';

/**
 * DON'T USE THIS SERVICE IN YOUR COMPONENTS, SERVICES OR OTHER PLACES.
 * USE THE LOGGER SERVICE IF YOU WANT TO MANUALLY SEND SOMETHING TO TRANSPORTERS.
 */
@Injectable({ providedIn: 'root' })
export class NxhErrorHandler implements ErrorHandler {
  constructor(@Inject(NxhLoggerToken) private logger: NxhLogger) {}

  handleError(error: ErrorSource): void {
    this.logger.error(error);
  }
}
