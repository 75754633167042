import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngneat/effects';
import { withTransaction } from '@datorama/akita';
import { addLoggerError } from './logger.actions';
import { LoggerStore } from './logger.store';

@Injectable({ providedIn: 'root' })
export class LoggerEffects {
  private _loggerStore: LoggerStore = inject(LoggerStore);

  addLoggerError$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(addLoggerError),
        withTransaction((entity) => {
          const logMessage = entity.logMessage;
          this._loggerStore.upsert(
            logMessage.traceId,
            (ent) => ({ ...ent, ...logMessage }),
            (id, newState) => ({ id, ...newState })
          );
        })
      ),
    {
      dispatch: false,
    }
  );
}
