import { InjectionToken } from '@angular/core';
import { LoggerConfiguration, NxhLogger } from './logger.model';

/*
 * LoggerConfiguration Token
 */

export const LoggerConfigurationToken = new InjectionToken<LoggerConfiguration>('LoggerConfiguration');

/**
 * Logger Injection token
 */
export const NxhLoggerToken = new InjectionToken<NxhLogger>('NxhLogger');
