import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceName } from '@nexuzhealth/shared/domain';
import { EFactStatus, InvoiceStatus } from '@nexuzhealth/shared/finance/domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseEfactApiService } from './base-efact-api.service';

@Injectable()
export class EfactV2ApiService extends BaseEfactApiService {
  constructor(http: HttpClient) {
    super(http, 'api/finance/efact/v2', 'api/finance/efact/v2', 'api/finance/efact/v2/messages', ':');
  }

  getCounters(tenantName: ResourceName): Observable<Record<InvoiceStatus | EFactStatus, string>> {
    const url = `${this.countersUrl}/${tenantName}/count`;
    return this.http.get<{ counts: any }>(url).pipe(map((result) => result.counts));
  }
}
