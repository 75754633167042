import { AddressComponentNameModel, TranslationFormModel } from '@nexuzhealth/shared/street-management/domain';
import { languageResourceNameToId } from '@nexuzhealth/shared/util';

export function mapAddressComponentNameToTranslationForm(
  addressComponentName: AddressComponentNameModel
): TranslationFormModel {
  return {
    name: addressComponentName.name,
    languageId: languageResourceNameToId(addressComponentName.languageId),
    translation: addressComponentName.value,
    sendVersion: addressComponentName.sendVersion,
  };
}
