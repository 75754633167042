import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavbarItem } from '@nexuzhealth/shared/toolkit/feature-shell';
import { Observable } from 'rxjs';
import { AuthQuery, AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { map } from 'rxjs/operators';
import { AccessRole, OrganisationAccessRole, UserContext } from '@nexuzhealth/shared/domain';

@Component({
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellComponent implements OnInit {
  topNavigation$: Observable<NavbarItem[]>;

  constructor(private authQuery: AuthQuery, private authService: AuthService) {}

  ngOnInit(): void {
    this.topNavigation$ = this.authQuery.selectUserContext().pipe(
      map((context: UserContext) =>
        [
          {
            text: context.tenant.description,
            label: context.tenant.description,
            url: '/organisation',
            roleExpr: {
              or: [AccessRole.organisationManagement, AccessRole.userManagement, AccessRole.financialSettings],
            },
          },
          {
            label: '_apps._organizations-portal.link',
            url: '/my-organisations',
            roleExpr: { and: [AccessRole.suborganisationAdmin, OrganisationAccessRole.serviceProvider] },
          },
          {
            label: 'source-files',
            url: '/source-files',
            roleExpr: { and: [AccessRole.sourcefileManagement] },
          },
        ].filter((item) => this.authService.isAuthorized(item.roleExpr))
      )
    );
  }
}
