import {
  AddressComponentCodeModel,
  AddressComponentModel,
  AddressComponentNameModel,
  SubMunicipalityFormModel,
} from '@nexuzhealth/shared/street-management/domain';
import { languageIdToResourceName } from '@nexuzhealth/shared/util';

export function mapSubMunicipalityFormModelToAddressComponent(
  formModel: SubMunicipalityFormModel
): AddressComponentModel {
  const codes: AddressComponentCodeModel[] = [];
  formModel.codes?.forEach((code) => {
    codes.push({
      source: code.source,
      value: code.value,
    });
  });

  const names: AddressComponentNameModel[] = [];
  formModel.translations?.forEach((translation) => {
    names.push({
      value: translation.translation,
      languageId: languageIdToResourceName(translation.languageId),
    });
  });
  const result = {
    name: formModel.name,
    sendVersion: formModel.sendVersion,
    postalDescriptor: formModel.postalCode,
    status: formModel.status,
    names,
    codes,
    parent: { name: formModel.municipality?.name },
  };
  return result;
}
