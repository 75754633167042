import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MFAStatus } from '@nexuzhealth/shared/domain';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  private readonly baseUrl = 'api/iam/auth/v1/accounts';

  constructor(private http: HttpClient) {}

  getMFAStatus(userName: string): Observable<MFAStatus> {
    const url = `${this.baseUrl}/${userName}:mfastatus`;
    return this.http.get<MFAStatus>(url);
  }

  enableMFAStatus(userName: string) {
    const url = `${this.baseUrl}:enabledisable2fa`;
    return this.http.post(url, {
      userName: userName,
      enable: true,
      force: true,
    });
  }

  disableMFAStatus(userName: string) {
    const url = `${this.baseUrl}:enabledisable2fa`;
    return this.http.post(url, {
      userName: userName,
      enable: false,
      force: true,
    });
  }

  resetMFAStatus(userName: string) {
    const url = `${this.baseUrl}:resetmfa`;
    return this.http.post(url, {
      userName: userName,
      force: true,
    });
  }
}
