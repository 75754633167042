import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { LogMessage } from '../log.model';

export type LoggerState = EntityState<LogMessage, string>;

@StoreConfig({ name: 'logger', idKey: '_id', resettable: true })
@Injectable({ providedIn: 'root' })
export class LoggerStore extends EntityStore<LoggerState> {
  constructor() {
    super();
  }

  override akitaPreAddEntity(newEntity: LogMessage): LogMessage & { _id: string } {
    return {
      ...newEntity,
      _id: newEntity.traceId,
    };
  }
}
