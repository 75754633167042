import { ComponentSettings } from '@nexuzhealth/shared/settings/data-access-settings';
import { EFactStatus } from '@nexuzhealth/shared/finance/domain';

export const componentsConfig: ComponentSettings = {
  components: {
    finance: {
      efact: {
        allowMarkAsRefused: true,
        allowChangeAndRebill: false,
        allowCreditCertificate: false,
        allowCreditAndRebill: true,
        allowResubmitCredit: true,
        showAttestButton: false,
        showErrorsOnlyDefaultValue: false,
        showInvoiceMonth: true,
        processingEFactStatuses: [EFactStatus.sent],
        usesCertificate: false,
      },
      labelOverrides: {
        releaseInvoice: {
          tooltip: '_invoice._retry-rejected-hn.tooltip',
          success: '_invoice._retry-rejected-hn.success',
          failed: '_invoice._retry-rejected-hn.failed',
        },
        efactEmptyTable: '_loading-states.no-items-found',
      },
    },
  },
};
