import {
  AddressComponentFromSearchModel,
  AddressComponentListModel,
  AddressComponentModel,
  AddressComponentType,
} from '@nexuzhealth/shared/street-management/domain';
import { getFormattedTranslation } from './translation-mapper';

export function mapAddressComponentListToAddressComponentFromSearchModels(
  list: AddressComponentListModel
): AddressComponentFromSearchModel[] {
  // loop parents
  const res: AddressComponentFromSearchModel[] = [];
  list.addressComponents.forEach((adc) => {
    res.push(mapAddressComponentToAddressComponentFromSearchModel(adc));
  });

  // loop subs
  const subs: AddressComponentFromSearchModel[] = [];
  res.forEach((node) => {
    if (node.children != null) {
      node.children.forEach((adc) => {
        subs.push(mapAddressComponentToAddressComponentFromSearchModel(adc));
      });
    }
  });

  res.unshift(...subs);

  // make unique
  const filtered = res.filter((adc, index, selfArray) => index === selfArray.findIndex((p) => p.name === adc.name));
  filtered.sort((a, b) => a.translation.localeCompare(b.translation));
  return filtered;
}

export function mapAddressComponentToAddressComponentFromSearchModel(
  addressComponent: AddressComponentModel
): AddressComponentFromSearchModel {
  return {
    name: addressComponent.name ?? '',
    translation: getFormattedTranslation(addressComponent),
    type: addressComponent.type,
    postalCode: addressComponent.postalDescriptor,
    children: addressComponent.children,
  };
}
