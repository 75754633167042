import {
  AddressComponentCodeModel,
  AddressComponentModel,
  AddressComponentNameModel,
  AddressComponentStatusEnum,
  AddressComponentType,
  StreetFormModel,
} from '@nexuzhealth/shared/street-management/domain';
import { languageIdToResourceName } from '@nexuzhealth/shared/util';

export function mapStreetFormModelToAddressComponent(formModel: StreetFormModel): AddressComponentModel {
  const codes: AddressComponentCodeModel[] = [];
  formModel.codes?.forEach((code) => {
    codes.push({
      source: code.source,
      value: code.value,
    });
  });

  const names: AddressComponentNameModel[] = [];
  formModel.translations?.forEach((translation) => {
    if (translation.translation)
      names.push({
        value: translation.translation,
        languageId: languageIdToResourceName(translation.languageId),
      });
  });

  if (formModel.name === undefined) {
    // insert street
    return {
      status: AddressComponentStatusEnum.unvalidated,
      names,
      codes,
      parent: { name: formModel.subMunicipality?.name, type: AddressComponentType.submunicipality },
      type: AddressComponentType.street,
    };
  } else {
    // update street
    return {
      name: formModel.name,
      sendVersion: formModel.sendVersion,
      names,
      codes,
      parent: { name: formModel.subMunicipality?.name },
      status: formModel.status,
    };
  }
}
