import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedShellConfigService, ThemeService } from '@nexuzhealth/shared/toolkit/feature-shell';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nxh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbTooltipConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    tooltipConfig: NgbTooltipConfig,
    private themeSettings: ThemeService,
    sharedShellConfig: SharedShellConfigService
  ) {
    sharedShellConfig.setup(tooltipConfig);
  }

  ngOnInit(): void {
    this.themeSettings.getThemeSettings();
  }
}
