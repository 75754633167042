import { CountryConfigurationModel, CountryModel } from '@nexuzhealth/shared/street-management/domain';
import { languageResourceNameToId } from '@nexuzhealth/shared/util';

const sourceEnumToTranslationKeyMapping = new Map<string, string>([
  ['NIS', '_street-management._codes._sources.nis'],
  ['BOSA', '_street-management._codes._sources.bosa'],
]);

export function mapCountryConfigurationToCountryModel(list: CountryConfigurationModel[]): Array<CountryModel> {
  return list.map((countryConfiguration: CountryConfigurationModel) => {
    return {
      name: countryConfiguration.name ?? '',
      translation: countryConfiguration.translation,
      supportedLanguages: countryConfiguration.supportedLanguages.map((supportedLanguage) => {
        return {
          languageId: languageResourceNameToId(supportedLanguage.name),
        };
      }),
      supportedCodes: countryConfiguration.addressComponentCodes.map((supportedCode) => {
        const translationKey = sourceEnumToTranslationKeyMapping.get(supportedCode);
        return {
          code: supportedCode,
          translationKey: translationKey === undefined ? '' : translationKey,
        };
      }),
    };
  });
}
