import { guid } from '@datorama/akita';
import {
  DateTime,
  PagingResult,
  Patient,
  Payer,
  PayerType,
  PersonName,
  Practitioner,
  ResourceName,
} from '@nexuzhealth/shared/domain';

export interface Invoice {
  name?: ResourceName;
  sendVersion?: string;
  status?: InvoiceStatus;
  contactName?: ResourceName;
  sourceInvoiceId?: string;
  hasClone?: boolean;
  isCreditInvoice?: boolean;
  invoiceMonth?: string;

  type: AttestType;
  attendanceName: ResourceName;
  date?: string;
  lines: InvoiceLine[];

  attributes?: { [attributeName: string]: any };

  price?: number;
  priceExtended?: PriceExtended;
  payments?: Payment[];
  payer?: Payer;

  patient?: Partial<Patient>;

  externalResults?: ExternalResults;
  printRegistrations?: PrintRegistration[];
  thirdpayerPayments?: ThirdpayerPayment[];
  errors?: (string | InvoiceError)[];
}

export interface InvoiceLineWithErrors extends InvoiceLine {
  errors: string[];
}

export interface InvoiceViewModel extends Omit<Invoice, 'lines'> {
  lines: InvoiceLineWithErrors[];
  dates: string[];
  datesTooltip?: string;
  performancesSummary: string;
  performancesTooltip: string;
  cg1: string;
  mut: string;
  fee: number;
  supplement: number;
  paidByPatient: number;
  charge: number;
  statusSummary: string;
  paymentTypes?: PaymentType[];
  priceExtended?: PriceExtended;
  printDates: Date[];
  sendToPatientDates: Date[];
  eTar: boolean;
  errors: (string | InvoiceError)[];
}

export interface InvoiceError {
  translationKey?: string;
  translationParams?: Record<string, string>;
  message?: string;
  kind?: string;
  category?: string;
}

export interface InvoiceTotalPrice {
  price: Price;
  extraTotals?: { [key: string]: number };
}

export interface ReimbursementViewModel {
  acceptedAmount: number;
  rejectedAmount: number;
  hasErrors: boolean;
}

export enum AttestTypeReason {
  inssMissing = 'enums/init_invoice_reason/values/inss-missing',
  medicalHouse = 'enums/init_invoice_reason/values/medical-house',
  memberdataMissing = 'enums/init_invoice_reason/values/memberdata-missing',
  differentMemberdataDate = 'enums/init_invoice_reason/values/different-memberdata-date',
  error = 'enums/init_invoice_reason/values/error',
}

export const eAttestRevokeReason = 'finance_invoice_be-eattest-revoke-reason';

export interface Payment {
  name?: ResourceName;
  sendVersion?: string;
  amount: number;
  paymentType: PaymentType;
}

export interface InvoiceFilterOptions {
  patientName?: ResourceName;
  tenantName?: ResourceName;
  messageName?: ResourceName;
  fromDate?: Date;
  toDate?: Date;
  lineDefinitionName?: string;
  thirdPayer?: string;
  mutuality?: string;
  invoiceMonth?: string;
  payerId?: string;
  payerType?: PayerType;
}

export interface EFactMessage {
  name: ResourceName;
  sendVersion: string;
  vi: string; // mut
  invoicesMonth: DateTime; //{ Precision: string; value: Date }
  invoiceMonth: string;
  totalAmount: number; // invoiced amount
  acceptedAmount: number;
  refusedAmount: number;
  refusalReason: string;
  status: EFactStatus; // 'top' status like 'send', 'accepted', 'refused'
  sendIdentifier: string; // dispatch number
  sendDate: Date; // dispatch date
  statusDetail: EFactDetailStatus;
  receivedDate: Date;
  paid: boolean;
  paidDate: Date;
  invoices?: { invoice: Invoice; errors: string[] }[];
  wasReset?: boolean;
  invoiceStatusMap: InvoiceStatusMap;
  providerReference: string;
  paymentReference: string;
}

export interface EFactMessageWithInvoices {
  message: EFactMessage;
  invoices: PagingResult<{ invoice: Invoice; errors: string[] }>;
  containsRefusableInvoices: boolean;
}

export interface InvoiceStatusMap {
  MyCarenetAccepted?: number;
  MyCarenetRefused?: number;
}

export enum EFactReportTypes {
  accepted = 'accepted',
  partialAccepted = 'partial_accepted',
  archived = 'archived',
  processing = 'processing',
  rejected = 'rejected',
  unsent = 'unsent',
}

export enum OtherPayerReportTypes {
  unprocessed = 'unprocessed',
  processed = 'processed',
}

export interface EFactMessageInvoiceError {
  invoiceErrors: string[];
  lineErrors: Record<string, string[]>;
}

export interface EFactMessageFilterOptions {
  mutuality?: ResourceName;
  sendIdentifier?: string;
  fromDate?: Date;
  toDate?: Date;
  statusDetails?: string | string[];
  resetOnly?: boolean;
  unresetOnly?: boolean;
  invoiceMonth?: string;
}

export interface EFactMessagePatientSummaryFilterOptions {
  patientName?: ResourceName;
  errorsOnly?: boolean;
}

export interface PriceExtended {
  currency: string;
  practitionerFee: number;
  officialFee: number;
  supplementalFee: number;
  reimbursement?: number;
  patientFee?: number;
  totalToPay?: number;
}

export interface EFactMessagesPricesSummary {
  acceptedAmount: number;
  refusedAmount: number;
  totalAmount: number;
}

export interface EFactMessagesPatientSummary {
  errors: string[];
  messageName: ResourceName;
  patient: Partial<Patient>;
  mutuality: EFactMessagesPatientSummaryMutuality;
  containsRefusableInvoices: boolean;
  totalAmount: number;
  acceptedAmount: number;
  refusedAmount: number;
}

export interface EFactMessagesPatientSummaryMutuality {
  department: string;
  vi: string;
  origin: string;
  destination: string;
}

export type EFactMessagePatientSummaryPagingResult = {
  errors: string[];
  nextPageToken: string;
  totalSize: number;
  patientSummary: EFactMessagesPatientSummary[];
};

export interface PrintRegistration {
  blobName: ResourceName;
  printType: PrintRegistrationType;
  date: Date;
}

export enum PrintRegistrationType {
  print = '',
  sendToPatient = 'sendToPatient',
}

export enum PaymentType {
  cash = 'enums/finance_payment-method/values/cash',
  card = 'enums/finance_payment-method/values/bankcontact',
}

export interface ThirdpayerPayment {
  name: ResourceName;
  amount: number;
  paymentType: ThirdpayerPaymentType;
  sendVersion: string;
}

export enum ThirdpayerPaymentType {
  rejected = 'enums/finance_payment-method/values/thirdpayer-rejected',
  accepted = 'enums/finance_payment-method/values/thirdpayer-accepted',
}

// hardcoded until we have the notion of Attendacne in MOAPR
const attendance_name = encodeURI('patients/123/contacts/456/attendances/789');

export function createInvoice(attendanceName: ResourceName = attendance_name): Invoice {
  return {
    type: AttestType.eAttest,
    attendanceName,
    date: new Date().toISOString(),
    lines: [],
  };
}

export interface InvoiceLine {
  name?: ResourceName;
  sendVersion?: string;
  definitionName: ResourceName;
  description?: string;
  date: Date;
  price: Price;
  externalPrice?: Price;
  attributes: { [attributeName: string]: string };

  // client-side only
  id?: string; // we cannot use name or code, as we need to be able to store multiple Performances with identical codes
  code?: string; // we need this to show something meaningful
  hasValidationErrors?: boolean;
  practitioner?: Practitioner;
}

export function createInvoiceLine(invoiceLineDef: InvoiceLineDef, line: InvoiceLine): InvoiceLine {
  return {
    id: guid(),
    definitionName: invoiceLineDef.name,
    code: invoiceLineDef.code,
    description: invoiceLineDef.description,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    date: invoiceLineDef.date!,
    price: line.price,
    attributes: { ...invoiceLineDef.attributes, ...line.attributes },
  };
}

export interface Price {
  currency: string;
  officialFee: number;
  supplementalFee?: number;
  reimbursement?: number; // 'terugbetaald'
  patientFee?: number; // 'remgeld'
  practitionerFee?: number;
  totalToPay?: number;
}

export interface InvoiceLineDef {
  id?: string; // client-side only - we cannot use name or code, as we need to be able to store multiple Performances with identical codes
  name: ResourceName;
  date?: Date;
  code: string;
  description: string;
  price?: Price;
  attributes?: { [attributeName: string]: any };
}

export enum AttestType {
  eAttest = 'enums/finance_invoice_type/values/be-eattest',
  eFact = 'enums/finance_invoice_type/values/be-efact',
  paper = 'enums/finance_invoice_type/values/be-paper',
  otherPayers = 'enums/finance_invoice_type/values/be-other-payers',
}

export enum AttestSubType {
  invoice = 'enums/finance_invoice_subtype/values/invoice',
  credit = 'enums/finance_invoice_subtype/values/credit',
  correction = 'enums/finance_invoice_subtype/values/correction',
  resubmission = 'enums/finance_invoice_subtype/values/re-submission',
}

export enum InvoiceStatus {
  accepted = 'enums/finance_invoice_status/values/accepted',
  acceptedWithErrors = 'enums/finance_invoice_status/values/accepted-with-errors',
  sent = 'enums/finance_invoice_status/values/sent',
  cancelled = 'enums/finance_invoice_status/values/canceled',
  rejected = 'enums/finance_invoice_status/values/rejected',
  processing = 'enums/finance_invoice_status/values/processing',
  error = 'enums/finance_invoice_status/values/error',
  unsent = 'enums/finance_invoice_status/values/unsent',
}

export enum EFactStatus {
  accepted = 'enums/finance-efact_status/values/accepted',
  acceptedWithErrors = 'enums/finance-efact_status/values/accepted-with-errors',
  refused = 'enums/finance-efact_status/values/refused',
  sent = 'enums/finance-efact_status/values/sent',
  processing = 'enums/finance-efact_status/values/processing',
  preparing = 'enums/finance-efact_status/values/preparing',
  readyToSent = 'enums/finance-efact_status/values/ready-to-sent',
  receivedByMycarenet = 'enums/finance-efact_status/values/received-by-mycarenet',
}

export enum EFactDetailStatus {
  faultHigherThan5 = 'enums/finance-efact_status-detail/values/fault-higher-than-5',
  blockingError = 'enums/finance-efact_status-detail/values/blocking-error',
  rejected = 'enums/finance-efact_status-detail/values/rejected',
  readyToSend = 'enums/finance-efact_status-detail/values/ready-to-send',
  preparing = 'enums/finance-efact_status-detail/values/preparing',
  sent = 'enums/finance-efact_status-detail/values/sent',
  sendError = 'enums/finance-efact_status-detail/values/send-error',
  receivedByMycarenet = 'enums/finance-efact_status-detail/values/received-by-mycarenet',
  accepted = 'enums/finance-efact_status-detail/values/accepted',
  acceptedWithErrors = 'enums/finance-efact_status-detail/values/accepted-with-errors',
  inAnticipation = 'enums/finance-efact_status-detail/values/in-anticipation',
  acknowledgedByMycarenet = 'enums/finance-efact_status-detail/values/acknowledged-by-mycarenet',
}

export const faultHigherThan5AndWasReset = 'fault-higher-than-5-and-was-reset';
export const blockingErrorAndWasReset = 'blocking-error-and-was-reset';
export const rejectedAndWasReset = 'rejected-and-was-reset';

export interface InvoiceStatusInfo {
  statusName: InvoiceStatus;
  error?: { code: string; description?: string };
}

export interface ExternalResults {
  evidenceId: string;
  externalId: string;
}

export interface ETarStatusInfo {
  name?: ResourceName;
  statusName: InvoiceStatus;
  error?: { code: string; description: string };
}

export const ETAR_UNAVAILABLE = 'ehealth_etar_service-unavailable';
export const EATTEST_UNAVAILABLE = 'ehealth_eattest_service-unavailable';
export const EATTEST_CANCEL_UNAVAILABLE = 'ehealth_eattest_cancel-service-unavailable';

export interface Nomenclature {
  name: ResourceName;
  sendVersion: string;
  description: string;
}

export interface Mutuality {
  vi: string;
  translationKey: string;
  phoneNumber: string;
  email: string;
}

export interface OrganisationInvoiceDetails {
  contact: {
    personName: PersonName;
    phoneNumber: string;
  };
  financeAttributes: { [attributeName: string]: any };
}
