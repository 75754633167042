import { inject } from '@angular/core';
import { Actions } from '@ngneat/effects-ng';
import { addLoggerError } from '../state/logger.actions';
import { LogLevel, LogMessage } from '../log.model';
import { AbstractTransporter } from './common/transporter.abstract';
import { defaultTransporterTemplate, TransportTemplate } from './common/transporter.model';

export class StateTransporter<T extends LogMessage> extends AbstractTransporter<T> {
  private _stateActions = inject(Actions);
  /**
   * @param template optional if not given, using default (no template, as message comes is written)
   */
  constructor(template?: TransportTemplate<T>) {
    super(template || defaultTransporterTemplate);
  }
  override doWrite(payload: T, level?: LogLevel | undefined): void {
    switch (level) {
      case LogLevel.Info:
        break;
      case LogLevel.Debug:
        break;
      case LogLevel.Warn:
        break;
      case LogLevel.Error:
        this._stateActions.dispatch(addLoggerError({ logMessage: payload }));
        break;
    }
  }
}
