import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CountryModel } from '@nexuzhealth/shared/street-management/domain';

const idKey: keyof CountryModel = 'name';

export type CountryStateKey = CountryModel[typeof idKey];
export type CountryState = EntityState<CountryModel, CountryStateKey>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'countryModel', idKey, resettable: false })
export class CountryStore extends EntityStore<CountryState> {
  constructor() {
    super();
  }
}
