import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthorizedGuard } from '@nexuzhealth/shared/authentication/feature-authorization';
import { AuthGuard, redirectGuard } from '@nexuzhealth/shared/authentication/data-access-auth';
import { AccessRole, OrganisationAccessRole } from '@nexuzhealth/shared/domain';
import { ReferenceResolveService } from '@nexuzhealth/shared/reference-data/data-access';
import { FeatureFlagResolver } from '@nexuzhealth/shared/settings/feature-flags/data-access';
import { PageNotFoundComponent } from '@nexuzhealth/shared/ui-toolkit/not-found';
import { AuthenticatedThemeResolver } from '@nexuzhealth/shared/toolkit/feature-theming';
import { FatalErrorPageComponent } from '@nexuzhealth/shared/toolkit/feature-shell';
import { CountryConfigResolveService } from '@nexuzhealth/shared/street-management/data-access';
import { AppShellComponent } from './app-shell/app-shell.component';

const orgRoleExpr = {
  or: [AccessRole.organisationManagement, AccessRole.userManagement, AccessRole.financialSettings],
};
const routes: Routes = [
  {
    path: 'fatal-error',
    component: FatalErrorPageComponent,
  },
  {
    path: '',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'organisation',
        canActivate: [isAuthorizedGuard(orgRoleExpr)],
        resolve: { references: ReferenceResolveService },
        loadChildren: () =>
          import('@nexuzhealth/moapr-org/organisation/shell').then((m) => m.MoaprOrgOrganisationShellModule),
      },
      {
        path: 'my-organisations',
        loadChildren: () =>
          import('@nexuzhealth/moapr-org/my-organisations/shell').then((m) => m.MoaprOrgMyOrganisationsShellModule),
        canActivate: [
          isAuthorizedGuard({ and: [AccessRole.suborganisationAdmin, OrganisationAccessRole.serviceProvider] }),
        ],
        data: {
          breadcrumb: '_org._my-organisations._breadcrumb.title',
        },
        resolve: { references: ReferenceResolveService, countriesWithStructuredAddresses: CountryConfigResolveService },
      },
      {
        path: 'source-files',
        loadChildren: () =>
          import('@nexuzhealth/moapr-org/source-files/shell').then((m) => m.MoaprOrgSourceFilesShellModule),
        canActivate: [
          isAuthorizedGuard({ and: [AccessRole.suborganisationAdmin, OrganisationAccessRole.serviceProvider] }),
        ],
        data: {
          breadcrumb: 'source-files',
        },
        resolve: { references: ReferenceResolveService, countriesWithStructuredAddresses: CountryConfigResolveService },
      },
      {
        path: 'home',
        redirectTo: 'organisation',
        pathMatch: 'full',
      },
      {
        path: '',
        pathMatch: 'full',
        // redirectTo: 'organisation',
        canActivate: [
          redirectGuard([
            {
              roleExpr: orgRoleExpr,
              path: 'organisation',
            },
            {
              roleExpr: { and: [AccessRole.suborganisationAdmin, OrganisationAccessRole.serviceProvider] },
              path: 'my-organisations',
            },
            { path: 'not-found' },
          ]),
        ],
        children: [], // Required as per https://stackoverflow.com/questions/54601926/how-to-use-guard-without-component-in-angular
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
    resolve: {
      theme: AuthenticatedThemeResolver,
      featureFlags: FeatureFlagResolver,
    },
    data: {
      flagNames: [
        'featureflags/select-french',
        'featureflags/billing',
        'featureflags/patient-batch-export',
        'featureflags/ehealthbox-organisation',
        'featureflags/shared-inbox',
      ],
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      initialNavigation: 'disabled',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
