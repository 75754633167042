import {
  AddressComponentListModel,
  AddressComponentModel,
  SubMunicipalityModel,
} from '@nexuzhealth/shared/street-management/domain';
import { mapAddressComponentToMunicipality } from './address-component-to-municipality.mapper';
import { getFirstTranslation, getFormattedTranslation } from './translation-mapper';

export function mapAddressComponentListToSubMunicipalities(list: AddressComponentListModel): SubMunicipalityModel[] {
  return list.addressComponents.map((addressComponent) => mapAddressComponentToSubMunicipality(addressComponent));
}

export function mapAddressComponentToSubMunicipality(addressComponent: AddressComponentModel): SubMunicipalityModel {
  const municipality = addressComponent.parent ? mapAddressComponentToMunicipality(addressComponent.parent) : undefined;

  return {
    name: addressComponent.name ?? '',
    postalCode: addressComponent.postalDescriptor || '',
    translation: getFirstTranslation(addressComponent),
    formattedTranslation: getFormattedTranslation(addressComponent),
    municipality,
  };
}
