import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot, Router } from '@angular/router';
import { RoleExpr } from '@nexuzhealth/shared/domain';
import { map, take } from 'rxjs/operators';
import { AuthQuery } from '../state/auth-query.service';
import { isAuthorized } from './authorization-utils';

export const redirectGuard: (redirectInfo: RedirectInfo) => CanActivateFn = (redirectInfo: RedirectInfo) => {
  return (next: ActivatedRouteSnapshot) => {
    const authQuery = inject(AuthQuery);
    const router = inject(Router);

    if (!redirectInfo || !redirectInfo.some((path) => !path.roleExpr)) {
      console.error('Using RedirectGuard without providing redirectInfo. Programming error?');
      return false;
    }

    return authQuery.allRoles$.pipe(
      take(1),
      map((roles) => {
        const redirectTo = redirectInfo.find((path) => {
          return isAuthorized(path.roleExpr, roles);
        })?.path;

        if (!redirectTo) {
          console.error('Using RedirectGuard without providing redirectInfo with fallback. Programming error?');
          return false;
        }

        return createUrlTreeFromSnapshot(next, [redirectTo]);
      })
    );
  };
};

export type RedirectInfo = { roleExpr?: RoleExpr; path: string }[];
