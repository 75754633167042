import {
  AttestType,
  EFactMessage,
  EFactMessageFilterOptions,
  EFactMessageInvoiceError,
  EFactMessagePatientSummaryFilterOptions,
  EFactMessagePatientSummaryPagingResult,
  EFactMessagesPatientSummary,
  EFactMessagesPricesSummary,
  EFactMessageWithInvoices,
  EFactStatus,
  Invoice,
  InvoiceStatus,
} from '@nexuzhealth/shared/finance/domain';
import { getHttpParams, SortOptions, sortOptionsToString, timeoutHeaders } from '@nexuzhealth/shared/util';
import { map, Observable } from 'rxjs';
import { PagingResult, ResourceName } from '@nexuzhealth/shared/domain';
import { HttpClient } from '@angular/common/http';

export interface GetListEFactMessagesParams extends Paging, EFactMessageFilterOptions {
  statuses: EFactStatus[];
}

export interface GetMessagePatientSummariesListParams extends Paging, EFactMessagePatientSummaryFilterOptions {}

interface Paging {
  pageToken?: string;
  pageSize: number;
  orderBy: string | null;
}

export abstract class BaseEfactApiService {
  protected constructor(
    protected http: HttpClient,
    private readonly efactUrl: string,
    protected readonly countersUrl: string,
    private readonly sendUrl: string,
    private readonly efactResetSeparator: string
  ) {}

  listEFactMessages(params: GetListEFactMessagesParams): Observable<PagingResult<EFactMessage>> {
    const url = `${this.efactUrl}/messages`;
    return this.http
      .get<Partial<PagingResult<EFactMessage>>>(url, {
        params: getHttpParams(params),
      })
      .pipe(
        map((result) => ({
          data: result['messages'],
          totalSize: result.totalSize,
          pageToken: result.nextPageToken,
        }))
      );
  }

  getEFactMessage(messageName: ResourceName, view = 'RESPONSES'): Observable<EFactMessage> {
    const params = getHttpParams({ view });
    const url = `${this.efactUrl}/${messageName}`;
    return this.http.get<{ message: EFactMessage }>(url, { params }).pipe(map((result) => result.message));
  }

  getEFactMessageByInvoiceName(invoiceName: ResourceName): Observable<EFactMessage> {
    const params = getHttpParams({ invoiceName });
    const url = `${this.efactUrl}/message`;
    return this.http.get<{ message: EFactMessage }>(url, { params }).pipe(map((result) => result.message));
  }

  getEFactMessageInvoices<T extends Invoice>(
    messageName: ResourceName,
    patientName: ResourceName | undefined,
    pageSize: number,
    status: InvoiceStatus[],
    sortOptions: SortOptions<T>,
    pageToken: string
  ): Observable<EFactMessageWithInvoices> {
    const params = getHttpParams({
      patientName,
      pageSize,
      pageToken,
      orderBy: sortOptionsToString(sortOptions),
      view: 'INVOICES',
      invoiceStatuses: status,
    });
    const url = `${this.efactUrl}/${messageName}`;
    return this.http
      .get<{ message: EFactMessage; containsRefusableInvoices: boolean; nextPageToken: string; totalSize: number }>(
        url,
        { params }
      )
      .pipe(
        map((response) => ({
          message: response.message,
          containsRefusableInvoices: response.containsRefusableInvoices,
          invoices: {
            data: response.message.invoices,
            pageToken: response.nextPageToken,
            totalSize: response.totalSize,
          },
        }))
      );
  }

  getMessagePatientSummaries(
    messageName: ResourceName,
    params: GetMessagePatientSummariesListParams
  ): Observable<{ summaries: PagingResult<EFactMessagesPatientSummary>; errors: string[] }> {
    const url = `${this.efactUrl}/patients/${messageName}`;
    return this.http.get<EFactMessagePatientSummaryPagingResult>(url, { params: getHttpParams(params) }).pipe(
      map((response) => ({
        summaries: {
          data: response.patientSummary,
          pageToken: response.nextPageToken,
          totalSize: response.totalSize,
        },
        errors: response.errors,
      }))
    );
  }

  getEFactMessageInvoiceError(
    messageName: ResourceName,
    invoiceName: ResourceName
  ): Observable<EFactMessageInvoiceError> {
    const url = `${this.efactUrl}/${messageName}/errors?invoiceName=${invoiceName}`;
    return this.http.get<EFactMessageInvoiceError>(url);
  }

  getEFactMessagesPricesSummary(
    tenantName: ResourceName,
    statuses: EFactStatus[],
    filterOptions: EFactMessageFilterOptions = {}
  ): Observable<EFactMessagesPricesSummary> {
    const url = `${this.efactUrl}/messages:summary`;
    const params = getHttpParams({
      statuses,
      ...filterOptions,
    });
    return this.http
      .get<EFactMessagesPricesSummary>(url, { params })
      .pipe(map((totals) => ({ acceptedAmount: 0, refusedAmount: 0, totalAmount: 0, ...totals })));
  }

  sendAll() {
    const url = `${this.sendUrl}:efactbatch`;
    return this.http.post<{ invoice: Invoice }>(url, {});
  }

  saveEFactMessage(msg: EFactMessage): Observable<EFactMessage> {
    const url = `${this.efactUrl}/${msg.name}?updateMask=paid,paidDate`;
    return this.http.patch<Record<'message', EFactMessage>>(url, msg).pipe(
      map((resp) => ({
        ...resp.message,
        paidDate: resp.message.paidDate || null,
      }))
    );
  }

  retryEFactMessage(msg: EFactMessage) {
    const url = `${this.efactUrl}/${msg.name}${this.efactResetSeparator}reset`;
    return this.http.post(
      url,
      { sendVersion: msg.sendVersion },
      { headers: timeoutHeaders({ errorMillis: 60 * 1000 }) } // Set timeout to 60 seconds or else the call fails.
    );
  }

  refuseEFactInvoice(invoiceName?: ResourceName, messageName?: ResourceName, noCredit: boolean = false) {
    const url = `${this.efactUrl}/${invoiceName}:refuse`;
    const body = { messageName, noCredit };
    return this.http.post(url, body);
  }

  bulkRefuseEFactInvoice(patientName: ResourceName, messageName: ResourceName, noCredit: boolean = false) {
    const url = `${this.efactUrl}/${messageName}/${patientName}:refuse`;
    const body = { noCredit };
    return this.http.post(url, body);
  }

  abstract getCounters(
    tenantName: ResourceName,
    type?: AttestType
  ): Observable<Record<InvoiceStatus | EFactStatus, string>>;
}
