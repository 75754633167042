import { UserContextInformation } from '@nexuzhealth/shared/user-management/domain';
import { Store, StoreConfig } from '@datorama/akita';
import { Error } from '@nexuzhealth/shared/domain';
import { Injectable } from '@angular/core';

export interface UserManagementState extends UserContextInformation {
  successfulEdit: boolean;
  pageError: Error | null;
}

export function createInitialState(): UserManagementState {
  return {
    generalInfo: null,
    user: null,
    practitioner: {
      name: '',
      contextName: '',
      displayName: null,
      nihii: '',
      email: '',
      mobile: '',
      phone: '',
    },
    userInvoiceInformation: null,
    userContextInvoiceInformation: null,
    successfulEdit: false,
    pageError: null,
  };
}

@StoreConfig({ name: 'user-management', resettable: true })
@Injectable({ providedIn: 'root' })
export class UserManagementStore extends Store<UserManagementState> {
  constructor() {
    super(createInitialState());
  }
}
