import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  UserContextInformation,
  UserContextInformationPractitioner,
  UserContextInformationPractitionerUpdate,
  UserContextInformationUser,
  UserContextInformationUserUpdate,
  UserContextInvoiceInformation,
  UserInvoiceInformation,
} from '@nexuzhealth/shared/user-management/domain';
import { Observable, of } from 'rxjs';
import { AccountApiService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PersonInformation } from '@nexuzhealth/shared/person/domain';

@Injectable({ providedIn: 'root' })
export class UserManagementHttpService {
  constructor(private http: HttpClient, private accountApiService: AccountApiService) {}

  getUserContextInformation(resourceName: string): Observable<UserContextInformation> {
    return this.http.get<UserContextInformation>(`api/iam/identitymgmt/v1/${resourceName}`).pipe(
      switchMap((userInfo) => {
        if (userInfo?.user) {
          return this.accountApiService.getMFAStatus(userInfo.user?.name).pipe(
            map((mfaStatus) => (userInfo?.user ? { ...userInfo, user: { ...userInfo.user, mfaStatus } } : userInfo)),
            catchError(() =>
              userInfo?.user ? of({ ...userInfo, user: { ...userInfo.user, mfaStatus: null } }) : of(userInfo)
            )
          );
        }
        return of(userInfo);
      })
    );
  }

  updateUserContextInformationGeneralInfo(
    userContextName: string | null | undefined,
    userContextInformationUser: PersonInformation
  ): Observable<PersonInformation> {
    return this.http.post<PersonInformation>(
      `api/iam/identitymgmt/v1/${userContextName}:general-information`,
      userContextInformationUser
    );
  }

  updateUserContextInformationUser(
    userContextName: string | null,
    userContextInformationUser: UserContextInformationUserUpdate
  ): Observable<UserContextInformationUser> {
    return this.http.post<UserContextInformationUser>(
      `api/iam/identitymgmt/v1/${userContextName}:user-information`,
      userContextInformationUser
    );
  }

  getUserInvoiceInformation(userName: string): Observable<UserInvoiceInformation> {
    return this.http.get<UserInvoiceInformation>(`api/app/user/v1/${userName}:invoice-information`);
  }

  updateUserInvoiceInformation(
    userName: string,
    information: UserInvoiceInformation
  ): Observable<UserInvoiceInformation> {
    return this.http.post<UserInvoiceInformation>(`api/app/user/v1/${userName}:invoice-information`, information);
  }

  updateUserProfessionalInformation(
    userContextName: string,
    isNihiiPending: boolean,
    userProfessionalInformation: UserContextInformationPractitionerUpdate
  ): Observable<UserContextInformationPractitioner> {
    return this.http.post<UserContextInformationPractitioner>(
      `api/iam/identitymgmt/v1/${userContextName}:professional-information`,
      { isNihiiPending, ...userProfessionalInformation }
    );
  }

  getUserContextInvoiceInformation(userContextName: string): Observable<UserContextInvoiceInformation> {
    return this.http.get<UserContextInvoiceInformation>(`api/app/user/v1/${userContextName}:invoice-information`);
  }

  updateUserContextInvoiceInformation(
    userContextName: string,
    userContextInvoiceInformation: UserContextInvoiceInformation
  ): Observable<UserContextInvoiceInformation> {
    return this.http.post<UserContextInvoiceInformation>(
      `api/app/user/v1/${userContextName}:invoice-information`,
      userContextInvoiceInformation
    );
  }
}
