import {
  AddressComponentListModel,
  AddressComponentModel,
  MunicipalityModel,
} from '@nexuzhealth/shared/street-management/domain';

export function mapAddressComponentListToMunicipalities(list: AddressComponentListModel): MunicipalityModel[] {
  return list.addressComponents.map((addressComponent) => mapAddressComponentToMunicipality(addressComponent));
}

export function mapAddressComponentToMunicipality(addressComponent: AddressComponentModel): MunicipalityModel {
  return {
    name: addressComponent.name ?? '',
    translation: addressComponent.names ? addressComponent.names[0]?.value ?? '' : '',
  };
}
