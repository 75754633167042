import {
  LoggerConfiguration,
  LoggerConfigurationToken,
  LoggerEffects,
  LoggerService,
  LogLevel,
  NxhErrorHandler,
  NxhLoggerToken,
  TRANSPORTER_PROVIDERS,
} from '@nexuzhealth/shared/logger/utils';
import { EnvironmentProviders, ErrorHandler, makeEnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngneat/effects-ng';
import { Optional } from 'utility-types';

export function provideLogger(config?: Optional<LoggerConfiguration, 'level'>) {
  const providers: Array<Provider | EnvironmentProviders> = [
    {
      // used to pass any external information that logger might need
      // to work (urls, tokens, etc, so far only level we are providing)
      provide: LoggerConfigurationToken,
      useValue: { level: config?.level !== undefined ? config.level : LogLevel.Error },
    },
    {
      /**
       * Here we provide our logger by default if logger module is imported.
       * and that's it, transporters and template are the ones whe will be
       * provided entirely by external source (in case you wrap this in a library)
       * we leave open that door but we set the rules with the interfaces.
       */
      provide: NxhLoggerToken,
      useClass: LoggerService,
    },
    ...TRANSPORTER_PROVIDERS,
    provideEffects(LoggerEffects),
  ];

  if (!config?.disableNxhErrorHandler) {
    providers.push(
      // Override default angular error handler with ours
      { provide: ErrorHandler, useClass: NxhErrorHandler }
    );
  }

  return makeEnvironmentProviders(providers);
}
