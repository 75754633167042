import { LogLevel, LogMessage } from '../log.model';
import { AbstractTransporter } from './common/transporter.abstract';
import { defaultTransporterTemplate, TransportTemplate } from './common/transporter.model';

/**
 * Console transporter
 */
export class ConsoleTransporter<T extends LogMessage> extends AbstractTransporter<T> {
  /**
   * @param template optional if not given, using default (no template, as message comes is written)
   */
  constructor(template?: TransportTemplate<T>) {
    super(template || defaultTransporterTemplate);
  }
  /**
   * writes payload in console
   */
  override doWrite(payload: T, level: LogLevel): void {
    switch (level) {
      case LogLevel.Info:
        return console.info(payload.timestamp, payload.message, payload.stack);
      case LogLevel.Debug:
        return console.debug(payload.timestamp, payload.message, payload.stack);
      case LogLevel.Warn:
        return console.warn(payload.timestamp, payload.message, payload.stack);
      case LogLevel.Error:
        return console.error(payload.timestamp, payload.message, payload.stack);
      default:
        return console.log(payload.timestamp, payload.message, payload.stack);
    }
  }
}
