import {
  AddressComponentCodeModel,
  AddressComponentModel,
  AddressComponentNameModel,
  MunicipalityFormModel,
} from '@nexuzhealth/shared/street-management/domain';
import { languageIdToResourceName } from '@nexuzhealth/shared/util';

export function mapMunicipalityFormModelToAddressComponent(formModel: MunicipalityFormModel): AddressComponentModel {
  const codes: AddressComponentCodeModel[] = [];
  formModel.codes?.forEach((code) => {
    codes.push({
      source: code.source,
      value: code.value,
    });
  });

  const names: AddressComponentNameModel[] = [];
  formModel.translations?.forEach((translation) => {
    if (translation.translation)
      names.push({
        value: translation.translation,
        languageId: languageIdToResourceName(translation.languageId),
      });
  });
  return {
    name: formModel.name,
    sendVersion: formModel.sendVersion,
    names,
    codes,
    parent: { name: formModel.countryId },
    status: formModel.status,
  };
}
