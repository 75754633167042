import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import {
  AddressComponentFromSearchModel,
  AddressComponentListModel,
  AddressComponentModel,
  AddressComponentStatusEnum,
  AddressComponentType,
  CountryConfigurationListModel,
  CountryModel,
  MunicipalityFormModel,
  SearchFilterModel,
  StreetFormModel,
  StreetModel,
  SubMunicipalityFormModel,
  SubMunicipalityModel,
} from '@nexuzhealth/shared/street-management/domain';
import { catchError, map, pluck, takeUntil, tap } from 'rxjs/operators';
import {
  mapCountryConfigurationToCountryModel,
  mapMunicipalityFormModelToAddressComponent,
  mapSubMunicipalityFormModelToAddressComponent,
  mapStreetFormModelToAddressComponent,
  mapAddressComponentListToSubMunicipalities,
  mapAddressComponentListToStreets,
  mapAddressComponentToMunicipalityForm,
  mapAddressComponentToStreetForm,
  mapAddressComponentToSubMunicipalityForm,
  mapAddressComponentListToAddressComponentFromSearchModels,
} from '@nexuzhealth/shared/street-management/util';
import { getHttpParams, SortOptions, sortOptionsToString } from '@nexuzhealth/shared/util';
import { PagingResult } from '@nexuzhealth/shared/domain';
import { cacheable } from '@datorama/akita';
import { CountryStore } from '../state/country.store';

@Injectable({ providedIn: 'root' })
export class StreetManagementService implements OnDestroy {
  private destroy$$ = new Subject<void>();

  constructor(private http: HttpClient, private countryStore: CountryStore) {}

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  getCountriesWithStructuredAddresses(): Observable<CountryModel[] | undefined> {
    const request$ = this.http.get<CountryConfigurationListModel>(`api/bas/address/v1/configurations`).pipe(
      takeUntil(this.destroy$$),
      map((list: CountryConfigurationListModel) => {
        return mapCountryConfigurationToCountryModel(list.configurations);
      }),
      tap((countries: Array<CountryModel>) => {
        this.countryStore.upsertMany(countries);
      }),
      catchError((error) => {
        this.countryStore.setError(error);
        this.countryStore.setLoading(false);
        return throwError(error);
      })
    );

    return cacheable(this.countryStore, request$);
  }

  createMunicipality(formModel: MunicipalityFormModel): Observable<AddressComponentModel> {
    return this.http
      .post<Record<'data', AddressComponentModel>>(
        'api/bas/address/v1/components',
        mapMunicipalityFormModelToAddressComponent(formModel)
      )
      .pipe(pluck('data'));
  }

  getMunicipality(name: string): Observable<MunicipalityFormModel> {
    return this.http
      .get<AddressComponentModel>(`api/bas/address/v1/${name}?allLanguagesForMain=true`)
      .pipe(map((addressComponentModel) => mapAddressComponentToMunicipalityForm(addressComponentModel)));
  }

  updateMunicipality(formModel: MunicipalityFormModel): Observable<AddressComponentModel> {
    return this.http
      .post<Record<'data', AddressComponentModel>>(
        'api/bas/address/v1/components',
        mapMunicipalityFormModelToAddressComponent(formModel)
      )
      .pipe(pluck('data'));
  }

  searchAddressComponentsForCountry(
    searchTerm: string,
    countryId: string,
    types: AddressComponentType[],
    showUnvalidated: boolean = false
  ): Observable<AddressComponentFromSearchModel[]> {
    const params = getHttpParams({
      types: types,
      term: searchTerm,
      statuses: this.getStatuses(showUnvalidated),
    });
    return this.http.get<AddressComponentListModel>(`api/bas/address/v1/${countryId}/components?`, { params }).pipe(
      map((list: AddressComponentListModel) => {
        return mapAddressComponentListToAddressComponentFromSearchModels(list);
      })
    );
  }

  searchSubMunicipalities(
    searchTerm: string,
    countryId: string,
    preferredLanguage: string,
    showUnvalidated: boolean = false
  ): Observable<SubMunicipalityModel[]> {
    const params = getHttpParams({
      types: AddressComponentType.submunicipality,
      term: searchTerm,
      statuses: this.getStatuses(showUnvalidated),
    });
    return this.http.get<AddressComponentListModel>(`api/bas/address/v1/${countryId}/components?`, { params }).pipe(
      map((list: AddressComponentListModel) => {
        return mapAddressComponentListToSubMunicipalities(list);
      })
    );
  }

  listSubMunicipalitiesForCountry(
    countryId: string,
    options: { pageSize: number; token?: string },
    filter: SearchFilterModel,
    showUnvalidated: boolean,
    sortOptions: SortOptions<SubMunicipalityModel>
  ): Observable<PagingResult<SubMunicipalityModel>> {
    let params = new HttpParams();
    params = params.appendAll({
      pageToken: options.token || '',
      pageSize: options.pageSize,
      types: AddressComponentType.submunicipality,
      statuses: this.getStatuses(showUnvalidated),
      orderBy: sortOptionsToString(sortOptions),
    });
    if (filter.subMunicipalityFilter.searchTerm) {
      params = params.append('term', filter.subMunicipalityFilter.searchTerm);
    }
    if (filter.subMunicipalityFilter.postalCode) {
      params = params.append('postalCode', filter.subMunicipalityFilter.postalCode);
    }
    return this.http.get<AddressComponentListModel>(`api/bas/address/v1/${countryId}/components`, { params }).pipe(
      map((result) => ({
        totalSize: result.totalSize,
        pageToken: result.nextPageToken,
        data: mapAddressComponentListToSubMunicipalities(result),
      }))
    );
  }

  listStreets(
    options: { pageSize: number; token?: string },
    filter: SearchFilterModel,
    showUnvalidated: boolean,
    sortOptions: SortOptions<StreetModel>
  ): Observable<PagingResult<StreetModel>> {
    let params = new HttpParams();
    params = params.appendAll({
      pageToken: options.token || '',
      pageSize: options.pageSize,
      types: AddressComponentType.street,
      statuses: this.getStatuses(showUnvalidated),
      orderBy: sortOptionsToString(sortOptions),
    });
    if (filter.streetFilter.searchTerm) {
      params = params.append('term', filter.streetFilter.searchTerm);
    }
    return this.http
      .get<AddressComponentListModel>(`api/bas/address/v1/${filter.streetFilter.parent?.name}/components`, { params })
      .pipe(
        map((result) => ({
          totalSize: result.totalSize,
          pageToken: result.nextPageToken,
          data: mapAddressComponentListToStreets(result),
        }))
      );
  }

  private getStatuses(showUnvalidated: boolean): string[] {
    if (!showUnvalidated) return [];

    return [AddressComponentStatusEnum.active, AddressComponentStatusEnum.unvalidated];
  }

  createSubMunicipality(formModel: SubMunicipalityFormModel): Observable<AddressComponentModel> {
    return this.http
      .post<Record<'data', AddressComponentModel>>(
        'api/bas/address/v1/components',
        mapSubMunicipalityFormModelToAddressComponent(formModel)
      )
      .pipe(pluck('data'));
  }

  getSubMunicipality(name: string): Observable<SubMunicipalityFormModel> {
    return this.http
      .get<AddressComponentModel>(`api/bas/address/v1/${name}?allLanguagesForMain=true`)
      .pipe(map((addressComponentModel) => mapAddressComponentToSubMunicipalityForm(addressComponentModel)));
  }

  updateSubMunicipality(formModel: SubMunicipalityFormModel): Observable<AddressComponentModel> {
    return this.http
      .post<Record<'data', AddressComponentModel>>(
        'api/bas/address/v1/components',
        mapSubMunicipalityFormModelToAddressComponent(formModel)
      )
      .pipe(pluck('data'));
  }

  createStreet(formModel: StreetFormModel): Observable<AddressComponentModel> {
    return this.http
      .post<Record<'data', AddressComponentModel>>(
        'api/bas/address/v1/components',
        mapStreetFormModelToAddressComponent(formModel)
      )
      .pipe(pluck('data'));
  }

  getStreet(name: string): Observable<StreetFormModel> {
    return this.http.get<AddressComponentModel>(`api/bas/address/v1/${name}?allLanguagesForMain=true`).pipe(
      map((addressComponentModel) => {
        return {
          ...addressComponentModel,
        };
      }),
      map((addressComponentModel) => mapAddressComponentToStreetForm(addressComponentModel))
    );
  }

  updateStreet(formModel: StreetFormModel): Observable<AddressComponentModel> {
    return this.http
      .post<Record<'data', AddressComponentModel>>(
        'api/bas/address/v1/components',
        mapStreetFormModelToAddressComponent(formModel)
      )
      .pipe(pluck('data'));
  }
}
