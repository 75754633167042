import { AddressComponentModel, MunicipalityFormModel } from '@nexuzhealth/shared/street-management/domain';
import { mapAddressComponentNameToTranslationForm } from './address-component-name-to-translation-form.mapper';
import { mapAddressComponentCodeToForm } from './address-component-code-to-form.mapper';

export function mapAddressComponentToMunicipalityForm(addressComponent: AddressComponentModel): MunicipalityFormModel {
  return {
    name: addressComponent.name,
    countryId: addressComponent.parent?.name,
    sendVersion: addressComponent.sendVersion,
    translations: addressComponent.names?.map((name) => mapAddressComponentNameToTranslationForm(name)),
    codes: addressComponent.codes?.map((name) => mapAddressComponentCodeToForm(name)),
    status: addressComponent.status,
  };
}
