import { InjectionToken } from '@angular/core';
import { LogLevel, LogMessage } from '../../log.model';
import { CapturedPayload } from './captured-payload.model';

export interface Transporter<T> {
  write: (payload: CapturedPayload, level?: LogLevel) => void;

  doWrite(payload: T, level?: LogLevel): void;
}

export type TransportTemplate<T> = (p: CapturedPayload) => T;

export const TransporterToken = new InjectionToken<Transporter<any>[]>('transporter-token');

/**
 * Default ConsoleTransporter template
 */
export const defaultTransporterTemplate = <T extends LogMessage>(p: CapturedPayload) => {
  return {
    traceId: p.traceId,
    message: p.message,
    timestamp: p.timestamp,
    stack: p.stack,
  } as T;
};
