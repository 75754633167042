import { actionsFactory, props } from '@ngneat/effects';
import { Error } from '@nexuzhealth/shared/domain';
import {
  UserContextInformation,
  UserContextInformationPractitioner,
  UserContextInformationPractitionerUpdate,
  UserContextInformationUser,
  UserContextInformationUserUpdate,
  UserContextInvoiceInformation,
  UserInvoiceInformation,
} from '@nexuzhealth/shared/user-management/domain';
import { PersonInformation } from '@nexuzhealth/shared/person/domain';

export const userManagementActions = actionsFactory('user-management');

/**
 * USER CONTEXT INFORMATION
 */
export const fetchUserContextInformation = userManagementActions.create(
  '[PAGE] Load User Context Information',
  props<{ resourceName: string }>()
);
export const fetchUserContextInformationSuccess = userManagementActions.create(
  '[API] Load User Context Information Success',
  props<{
    userContextInformation: UserContextInformation;
  }>()
);
export const fetchUserContextInformationError = userManagementActions.create(
  '[API] Load User Context Information Error',
  props<{ error: Error }>()
);

/**
 * USER CONTEXT INFORMATION GENERAL INFO
 */
export const updateUserContextInformationGeneralInfo = userManagementActions.create(
  '[PAGE] Update User Context Information GeneralInfo',
  props<{
    userContextName: string | null | undefined;
    userContextInformationGeneralInfo: PersonInformation;
  }>()
);
export const updateUserContextInformationGeneralInfoSuccess = userManagementActions.create(
  '[API] Update User Context Information GeneralInfo Success',
  props<{ userContextInformationGeneralInfo: PersonInformation }>()
);
export const updateUserContextInformationGeneralInfoError = userManagementActions.create(
  '[API] Update User Context Information GeneralInfo Error',
  props<{ error: Error }>()
);

/**
 * USER CONTEXT INFORMATION USER (USER DATA)
 */
export const updateUserContextInformationUser = userManagementActions.create(
  '[PAGE] Update User Context Information User',
  props<{ userContextName: string | null; userContextInformationUser: UserContextInformationUserUpdate }>()
);
export const updateUserContextInformationUserSuccess = userManagementActions.create(
  '[API] Update User Context Information User Success',
  props<{ userContextInformationUser: UserContextInformationUser }>()
);
export const updateUserContextInformationUserError = userManagementActions.create(
  '[API] Update User Context Information User Error',
  props<{ error: Error }>()
);

/**
 * PROFESSIONAL INFORMATION (USER CONTEXT PRACTITIONER)
 */
export const updateUserContextInformationPractitioner = userManagementActions.create(
  '[PAGE] Update User Context Information Practitioner',
  props<{
    userContextName: string | null;
    isNihiiPending: boolean;
    userProfessionalInformation: UserContextInformationPractitionerUpdate;
  }>()
);
export const updateUserContextInformationPractitionerSuccess = userManagementActions.create(
  '[API] Update User Context Information Practitioner Success',
  props<{ userContextInformationPractitioner: UserContextInformationPractitioner; isNihiiPending: boolean }>()
);
export const updateUserContextInformationPractitionerError = userManagementActions.create(
  '[API] Update User Context Information Practitioner Error',
  props<{ error: Error }>()
);

/**
 * INVOICE INFORMATION
 */
export const fetchUserInvoiceInformation = userManagementActions.create(
  '[PAGE] Load User Invoice Information',
  props<{ userName: string }>()
);
export const fetchUserInvoiceInformationSuccess = userManagementActions.create(
  '[API] Load User Invoice Information Success',
  props<{ userInvoiceInformation: UserInvoiceInformation }>()
);
export const fetchUserInvoiceInformationError = userManagementActions.create(
  '[API] Load User Invoice Information Error',
  props<{ error: Error }>()
);
export const updateUserInvoiceInformation = userManagementActions.create(
  '[PAGE] Update User Invoice Information',
  props<{ userName: string | null; userInvoiceInformation: UserInvoiceInformation }>()
);
export const updateUserInvoiceInformationSuccess = userManagementActions.create(
  '[API] Update User Invoice Information Success',
  props<{ userInvoiceInformation: UserInvoiceInformation }>()
);
export const updateUserInvoiceInformationError = userManagementActions.create(
  '[API] Update User Invoice Information Error',
  props<{ error: Error }>()
);

/**
 * USER CONTEXT INFORMATION
 */
export const fetchUserContextInvoiceInformation = userManagementActions.create(
  '[PAGE] Load User Context Invoice Information',
  props<{ userContextName: string }>()
);
export const fetchUserContextInvoiceInformationSuccess = userManagementActions.create(
  '[API] Load User Context Invoice Information Success',
  props<{ userContextInvoiceInformation: UserContextInvoiceInformation }>()
);
export const fetchUserContextInvoiceInformationError = userManagementActions.create(
  '[API] Load User Context Invoice Information Error',
  props<{ error: Error }>()
);
export const updateUserContextInvoiceInformation = userManagementActions.create(
  '[PAGE] Update User Context Invoice Information',
  props<{ userContextName: string | null; userContextInvoiceInformation: UserContextInvoiceInformation }>()
);
export const updateUserContextInvoiceInformationSuccess = userManagementActions.create(
  '[API] Update User Context Invoice Information Success',
  props<{ userContextInvoiceInformation: UserContextInvoiceInformation }>()
);
export const updateUserContextInvoiceInformationError = userManagementActions.create(
  '[API] Update User Context Invoice Information Error',
  props<{ error: Error }>()
);
