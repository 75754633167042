import { LogMessage } from '../log.model';
import { CapturedPayload } from './common/captured-payload.model';
import { TransporterToken } from './common/transporter.model';
import { ConsoleTransporter } from './console.transporter';
import { StateTransporter } from './state.transporter';

export const TRANSPORTER_PROVIDERS = [
  // provide Console transporter
  { provide: TransporterToken, useFactory: () => new ConsoleTransporter(), multi: true },
  // provide http transporter
  {
    provide: TransporterToken,
    useFactory: () => {
      const templateFn = (p: CapturedPayload) => {
        return <LogMessage>{
          traceId: p.traceId,
          message: p.message,
          timestamp: p.timestamp,
          stack: p.stack,
          kind: p.kind,
        };
      };
      /* any extra config you need */
      // create and return the transporter
      return new StateTransporter(templateFn);
    },
    multi: true,
  },

  // keep adding as many transporters you want, you make it crazy (ex: sentry transporter or slack transporter or even email transporter)
];
