<nxh-default-errors></nxh-default-errors>

<nxh-navbar>
  <ng-container class="left">
    <nxh-nav-item-simple *ngFor="let navItem of topNavigation$ | async" [navItem]="navItem"></nxh-nav-item-simple>
  </ng-container>
  <ng-container class="right">
    <nxh-nav-item-support-bubble></nxh-nav-item-support-bubble>
    <nxh-nav-item-user [userPortalLinkVisible]="true"></nxh-nav-item-user>
  </ng-container>
</nxh-navbar>

<div class="page-container"><router-outlet></router-outlet></div>
<nxh-cookie-bar></nxh-cookie-bar>
